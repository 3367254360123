<template>
  <div
    class="wrapper flex items-center"
    tabindex="0"
    @keyup.space="actionCheckbox"
    @keyup.enter="actionCheckbox"
    @click="actionCheckbox"
  >
    <input
      :id="id"
      :name="id"
      class="checkbox"
      type="checkbox"
      :checked="isChecked"
      :value="value"
      :disabled="disabled"
      @change="updateInput"
      tabindex="-1"
      ref="checkbox"
    />
    <span class="checkmark"></span>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    value: { default: "" },
    trueValue: { default: true },
    falseValue: { default: false },
    disabled: { default: false }
  },
  computed: {
    isChecked() {
      if (this.value instanceof Array) {
        return this.value.includes(this.trueValue);
      }
      return this.value === this.trueValue;
    }
  },
  methods: {
    actionCheckbox() {
      if (!this.disabled) {
        this.$refs.checkbox.click();
      }
    },
    updateInput(event) {
      let isChecked = event.target.checked;
      if (this.value instanceof Array) {
        let newValue = [...this.value];
        if (isChecked) {
          if (typeof this.falseValue === "string") {
            const index = newValue.indexOf(this.falseValue);
            if (index >= 0) {
              newValue.splice(index, 1);
            }
          }
          newValue.push(this.trueValue);
        } else {
          const index = newValue.indexOf(this.trueValue);
          if (index >= 0) {
            newValue.splice(index, 1);
          }
          if (typeof this.falseValue === "string") {
            newValue.push(this.falseValue);
          }
        }
        this.$emit("input", newValue);
      } else {
        this.$emit("input", isChecked ? this.trueValue : this.falseValue);
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
/* Create a custom checkbox */
.checkmark {
  @apply border border-blue-900 bg-white rounded-sm w-8 h-8 top-0 left-0 flex justify-center items-center;
}

/* Customize the label (the wrapper) */
.wrapper {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark::after {
  content: "";
  display: none;
}

/* On mouse-over, add a grey background color */
.wrapper:hover input ~ .checkmark {
  @apply bg-white;
}

/* When the checkbox is checked, add a blue background */
.wrapper input:checked ~ .checkmark {
  @apply bg-blue-900;
}

/* When the checkbox is disabled, add a gray background */
.wrapper input:disabled ~ .checkmark {
  @apply bg-gray-300 border-none;
}

/* Hide the browser's default checkbox */
.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Style the checkmark/indicator */
.wrapper .checkmark::after {
  top: 7px;
  left: auto;
  right: auto;
  width: 7px;
  height: 13px;

  @apply border border-white;

  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* Show the checkmark when checked */
.wrapper input:checked ~ .checkmark::after {
  display: block;
}

.wrapper input:disabled ~ .checkmark::after {
  @apply border-gray-500;
}
</style>
