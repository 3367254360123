<template>
  <div
    v-if="profileLoaded"
    class="flex flex-col w-full relative md:bg-white md:rounded-md"
    :class="isDesktop ? 'mt-14' : ''"
  >
    <div class="px-4 md:px-16 md:py-20">
      <div
        class="relative flex items-center"
        :class="isDesktop ? 'mb-12' : 'mt-14 mb-8'"
      >
        <h3 class="capitalize text-center inline mx-auto">
          {{ $t("message.newsletter-unsubscribe.heading") }}
        </h3>
      </div>
      <form @submit.prevent="updatePreferences()">
        <div class="md:w-1/2 md:mx-auto">
          <div class="option">
            <Checkbox
              id="team-updates"
              v-model="preferences"
              :trueValue="$system_config.teamUpdatesYes"
              :falseValue="$system_config.teamUpdatesNo"
              :disabled="
                loading ||
                  currentPreferences.includes($system_config.teamUpdatesNo)
              "
            />
            <label class="font-body" for="team-updates">{{
              $t("message.contact-preferences.team-updates")
            }}</label>
          </div>
          <div class="option">
            <Checkbox
              id="recommended-content"
              v-model="preferences"
              :trueValue="$system_config.recommendedContentYes"
              :falseValue="$system_config.recommendedContentNo"
              :disabled="
                loading ||
                  currentPreferences.includes(
                    $system_config.recommendedContentNo
                  )
              "
            />
            <label class="font-body" for="recommended-content">
              {{ $t("message.contact-preferences.recommended-content") }}
            </label>
          </div>
          <div class="option">
            <Checkbox
              id="news-and-offers"
              v-model="preferences"
              :trueValue="$system_config.marketingContentYes"
              :falseValue="$system_config.marketingContentNo"
              :disabled="
                loading ||
                  currentPreferences.includes($system_config.marketingContentNo)
              "
            />
            <label class="font-body" for="news-and-offers">
              {{ $t("message.contact-preferences.marketing-content") }}
            </label>
          </div>
          <button
            type="submit"
            class="btn btn-lg btn-default mt-8 my-4 mb-8"
            :disabled="!changesPending"
          >
            <span class="mx-auto" v-if="!loading">
              Save
            </span>
            <div class="loading w-6 h-6" v-else>
              <Spinner class="sm-button" />
            </div>
          </button>
          <h4 class="mb-4" v-if="fullyUnsubscribed">
            {{ $t("message.newsletter-unsubscribe.fully-unsubscribed") }}
          </h4>
          <p>{{ $t("message.newsletter-unsubscribe.resubscribe-hint") }}</p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { GET_PROFILE_BY_NEWSLETTER_UNSUBSCRIBE_ID } from "@/graphql/queries/user/userQueries.js";
import { NEWSLETTER_UNSUBSCRIBE } from "@/graphql/queries/user/userMutations.js";
import Spinner from "@/components/shared/Spinner.vue";
import { loadContactPreferencesAttribute } from "@/utils/basicHelpers";
import Checkbox from "@/components/form/Checkbox.vue";
import { mapGetters } from "vuex";
const system_config = require("@/constants/config.json");

const fullyUnsubscribedConfig = [
  system_config.teamUpdatesNo,
  system_config.recommendedContentNo,
  system_config.marketingContentNo
];

export default {
  name: "NewsletterUnsubscribe",
  components: {
    Checkbox,
    Spinner
  },
  data() {
    return {
      loading: false,
      profileLoaded: false,
      loadProfile: true,
      preferences: [],
      currentPreferences: []
    };
  },
  computed: {
    ...mapGetters("general", ["isDesktop"]),
    changesPending() {
      if (
        this.preferences &&
        this.currentPreferences &&
        this.preferences.length !== this.currentPreferences.length
      ) {
        return true;
      }
      for (let preference of this.currentPreferences) {
        if (this.preferences.indexOf(preference) < 0) {
          return true;
        }
      }
      return false;
    },
    fullyUnsubscribed() {
      for (const preference of fullyUnsubscribedConfig) {
        if (!this.currentPreferences.includes(preference)) {
          return false;
        }
      }
      return true;
    }
  },
  apollo: {
    profile: {
      query: GET_PROFILE_BY_NEWSLETTER_UNSUBSCRIBE_ID,
      variables() {
        return {
          newsletterUnsubscribeId: this.$route.params.newsletterUnsubscribeId
        };
      },
      update(response) {
        this.loadProfile = false;
        this.profileLoaded = true;
        const profile = response.getProfileByNewsletterUnsubscribeId;
        const translatedContactPreferences = loadContactPreferencesAttribute(
          profile.contactPreferences
        );
        this.preferences = [...translatedContactPreferences];
        this.currentPreferences = [...translatedContactPreferences];
        this.loading = false;
        return profile;
      },
      fetchPolicy: "no-cache",
      skip() {
        return !this.loadProfile;
      }
    }
  },
  methods: {
    updatePreferences() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$apollo.mutate({
        mutation: NEWSLETTER_UNSUBSCRIBE,
        variables: {
          profileId: this.profile.id,
          newsletterUnsubscribeId: this.$route.params.newsletterUnsubscribeId,
          contactPreferences: this.preferences.join(",")
        },
        update: () => {
          this.currentPreferences = [...this.preferences];
          this.loading = false;
          this.$toast.open({
            type: "success",
            message: this.$t("message.updated"),
            position: this.$store.getters["general/getToastPosition"],
            duration: 3000
          });
        },
        error() {
          this.loading = false;
          this.$toast.open({
            type: "error",
            message: this.$t("message.error"),
            position: this.$store.getters["general/getToastPosition"],
            duration: 0
          });
        }
      });
    }
  }
};
</script>

<style lang="postcss" scoped>
.option {
  @apply flex flex-row mt-6 items-center;
  label {
    @apply ml-4;
  }
}
</style>
