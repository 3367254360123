import gql from "graphql-tag";

export const PROFILE_BASIC_MUTATION = gql`
  mutation($newProfile: ProfileInput!) {
    editMyProfile(newProfile: $newProfile) {
      id
    }
  }
`;

export const MODERATE_PROFILE_MUTATION = gql`
  mutation($profileId: ID!, $newProfile: ProfileInput!) {
    moderatorEditProfile(profileId: $profileId, newProfile: $newProfile) {
      id
    }
  }
`;

export const FOLLOW_PROFILE = gql`
  mutation($profileId: ID!) {
    followProfile(profileId: $profileId)
  }
`;

export const UNFOLLOW_PROFILE = gql`
  mutation($profileId: ID!) {
    unfollowProfile(profileId: $profileId)
  }
`;

export const JOIN_TEAM = gql`
  mutation($teamId: ID!) {
    joinTeam(teamId: $teamId)
  }
`;

export const LEAVE_TEAM = gql`
  mutation($teamId: ID!) {
    leaveTeam(teamId: $teamId)
  }
`;

export const CREATE_POST = gql`
  mutation($newPost: PostInput!) {
    postCreate(newPost: $newPost) {
      id
    }
  }
`;

export const TASK_ACTION_PLEDGE = gql`
  mutation($teamId: ID!, $taskId: ID!) {
    taskStart(teamId: $teamId, taskId: $taskId)
    taskActionPledge(teamId: $teamId, taskId: $taskId)
  }
`;

export const TASK_ACTION_READ = gql`
  mutation($teamId: ID!, $taskId: ID!) {
    taskStart(teamId: $teamId, taskId: $taskId)
    taskActionRead(teamId: $teamId, taskId: $taskId)
  }
`;

export const TASK_ACTION_WATCH = gql`
  mutation($teamId: ID!, $taskId: ID!) {
    taskStart(teamId: $teamId, taskId: $taskId)
    taskActionWatch(teamId: $teamId, taskId: $taskId)
  }
`;

// prettier-ignore
export const TASK_ACTION_START_DONATION = gql`
  mutation taskActionStartDonation($amount: Int, $currency: String, $taskId: String, $beneficiaryId: String) {
    taskActionStartDonation(
      newPaymentIntent: {
        amount: $amount,
        currency: $currency,
        taskId: $taskId,
        beneficiaryId: $beneficiaryId
      }
    ) {
      id
      clientSecret
    }
  }
`;

export const MODERATOR_DISABLE_USER = gql`
  mutation moderatorDisableUser($profileId: ID!) {
    moderatorDisableUser(profileId: $profileId)
  }
`;

export const MODERATOR_ENABLE_USER = gql`
  mutation moderatorEnableUser($profileId: ID!) {
    moderatorEnableUser(profileId: $profileId)
  }
`;

export const NEWSLETTER_UNSUBSCRIBE = gql`
  mutation newsletterUnsubscribe(
    $profileId: ID!
    $newsletterUnsubscribeId: String!
    $contactPreferences: String!
  ) {
    newsletterUnsubscribe(
      profileId: $profileId
      newsletterUnsubscribeId: $newsletterUnsubscribeId
      contactPreferences: $contactPreferences
    ) {
      id
    }
  }
`;
